import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { MapInstructorsQuery } from '@graphql-types'
import { css } from '@emotion/react'
import StaffComponent from '@components/StaffComponent'
import MAPLayout from './_layout'
import { StaffWrap } from '@components/StaffWrap'

const MAPInstructorsPage = (props: PageProps<MapInstructorsQuery>) => {
	const { t } = useTranslation()
	const course = props.data.course!
	return (
		<MAPLayout course={course}>
			<h2>{t('courses:common.instructor_team')}</h2>
			<p>{t('instructor_description')}</p>
			<StaffWrap>
				{course?.instructors?.map((instructor, i) => (
					<StaffComponent key={i} staff={instructor}></StaffComponent>
				))}
			</StaffWrap>
		</MAPLayout>
	)
}

export default MAPInstructorsPage

export const query = graphql`
	query MAPInstructors($language: String!) {
		...TranslationFragment
		...MAPCoursePageFragment
	}
`
